import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import useInterval from "@rooks/use-interval";

import { loadProtocol } from "actions/global-info";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import { useActionState } from "hooks";
import { GlobalInfoStore } from "stores";

const Protocol = () => {
  const { protocolUrl, protocol } = GlobalInfoStore.useState();
  const [protocolLoading, protocolLoadError] = useActionState(loadProtocol);
  const [progressPercent, setProgressPercent] = useState(0);
  const [paused, setPaused] = useState(false);

  const forceLoad = useCallback(async () => {
    try {
      setPaused(true);
      setProgressPercent(1);
      await loadProtocol.run();
    } finally {
      setPaused(false);
    }
  }, [setPaused, setProgressPercent]);

  const tick = useCallback(async () => {
    if (paused) {
      return;
    }

    if (progressPercent % 100 === 0) {
      forceLoad();
    } else {
      setProgressPercent(progressPercent + 1);
    }
  }, [forceLoad, paused, progressPercent, setProgressPercent]);

  useInterval(tick, 100, true);

  const htmlContent = protocol
    ? {
        __html: protocol,
      }
    : null;

  const progressStyle = {
    position: "absolute",
    width: `${progressPercent}%`,
    height: "100%",
    left: "0",
    background:
      "linear-gradient(142deg, rgba(2,0,36,1) 0%, rgba(51,51,51,1) 0%, rgba(255,255,255,1) 100%)",
    opacity: "0.4",
  };

  return (
    <>
      <Helmet>
        <title>Zápis ze zasedání | CF 2022 | Pirátská strana</title>
        <meta
          name="description"
          content="Interaktivní zápis z on-line zasedání Celostátního fóra České pirátské strany, 8. 1. 2022."
        />
        <meta
          property="og:title"
          content="Zápis ze zasedání | CF 2022 | Pirátská strana"
        />
        <meta
          property="og:description"
          content="Interaktivní zápis z on-line zasedání Celostátního fóra České pirátské strany, 8. 1. 2022."
        />
      </Helmet>
      <article className="container container--default py-8 lg:py-24">
        <h1 className="head-alt-md lg:head-alt-lg mb-8">Zápis z jednání</h1>

        <div className="flex items-start">
          <div className="lg:w-2/3">
            {!protocolUrl && (
              <ErrorMessage>Zápis momentálně není k dispozici.</ErrorMessage>
            )}
            {protocolLoadError && (
              <ErrorMessage>
                Při stahování záznamu z jednání došlo k problému:{" "}
                {protocolLoadError.toString()}
              </ErrorMessage>
            )}
            {protocolUrl && <></>}
            {htmlContent && (
              <div
                className="leading-tight text-sm lg:text-base content-block"
                dangerouslySetInnerHTML={htmlContent}
              ></div>
            )}
          </div>
          <div className="hidden lg:block card elevation-10 w-1/3">
            <div className="lg:card__body content-block">
              <h2>Jak to funguje?</h2>
              <p>
                Zápis se aktualizuje automaticky každých 10 sekund. Pokud chceš
                aktualizaci vynutit ručně, můžeš využít tlačítko níže.
              </p>
              <Button
                icon="ico--refresh"
                loading={protocolLoading}
                className="btn--fullwidth"
                onClick={forceLoad}
                color="black"
                bodyProps={{
                  style: {
                    position: "relative",
                  },
                }}
              >
                <span style={progressStyle}></span>
                <span style={{ position: "relative" }}>
                  {protocolLoading ? "Aktualizace..." : "Aktualizovat"}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Protocol;
