import React from "react";
import { Helmet } from "react-helmet-async";

import Button from "components/Button";

const NotFound = () => (
  <>
    <Helmet>
      <title>404ka | CF 2022 | Pirátská strana</title>
      <meta name="description" content="Tahle stránka tu není." />
      <meta property="og:title" content="404ka | CF 2022 | Pirátská strana" />
      <meta property="og:description" content="Tahle stránka tu není." />
    </Helmet>
    <article className="container container--default py-8 lg:py-24">
      <h1 className="head-alt-base lg:head-alt-lg mb-8">
        404ka: tak tahle stránka tu není
      </h1>
      <p className="text-base lg:text-xl mb-8">
        Dostal/a ses na takzvanou „<strong>čtyřystačtyřku</strong>“, což
        znamená, že stránka, kterou jsi se pokusil/a navštívit, na tomhle webu
        není. Zkontroluj, zda máš správný odkaz.
      </p>
      <Button
        routerTo="/"
        className="text-base lg:text-xl"
        hoverActive
        fullwidth
      >
        Přejít na hlavní stránku
      </Button>
    </article>
  </>
);

export default NotFound;
