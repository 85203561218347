import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import useWindowSize from "@rooks/use-window-size";
import classNames from "classnames";

import Button from "components/Button";
import { AuthStore, GlobalInfoStore } from "stores";

const Navbar = ({ onGetHelp }) => {
  const { innerWidth } = useWindowSize();
  const [showMenu, setShowMenu] = useState();
  const { keycloak } = useKeycloak();
  const { isAuthenticated, user } = AuthStore.useState();
  const { connectionState } = GlobalInfoStore.useState();

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);
  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const connectionStateCaption = {
    connected: "Jsi online",
    offline: "Jsi offline",
    connecting: "Probíhá připojování",
  }[connectionState];

  const isLg = innerWidth >= 1024;

  const indicatorClass = {
    "bg-green-400": connectionState === "connected",
    "bg-red-600": connectionState === "offline",
    "bg-yellow-200": connectionState === "connecting",
  };

  const connectionIndicator = (
    <div className="inline-flex items-center">
      <span
        className="relative inline-flex h-4 w-4 mr-4"
        data-tip={connectionStateCaption}
        data-tip-at="left"
        aria-label={connectionStateCaption}
      >
        <span
          className={classNames(
            "animate-ping absolute inline-flex h-full w-full rounded-full opacity-75",
            indicatorClass
          )}
        />
        <span
          className={classNames(
            "inline-flex rounded-full w-4 h-4",
            indicatorClass
          )}
        />
      </span>
      <span className="hidden md:block text-grey-200">
        {connectionStateCaption}
      </span>
    </div>
  );

  return (
    <nav className="navbar navbar--simple">
      <div className="container container--wide navbar__content navbar__content--initialized">
        <div className="navbar__brand my-4 flex items-center lg:pr-8 lg:my-0">
          <NavLink to="/">
            <img
              src={`${process.env.REACT_APP_STYLEGUIDE_URL}/images/logo-round-white.svg`}
              className="w-8"
              alt="Pirátská strana"
            />
          </NavLink>
          <NavLink
            to="/"
            className="pl-4 font-bold text-xl lg:border-r lg:border-grey-300 lg:pr-8 hover:no-underline"
          >
            Celostátní fórum 2022
          </NavLink>
        </div>
        <div className="navbar__menutoggle my-4 flex justify-end lg:hidden">
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="no-underline hover:no-underline"
          >
            <i className="ico--menu text-3xl"></i>
          </button>
        </div>
        {(showMenu || isLg) && (
          <>
            <div className="navbar__main navbar__section navbar__section--expandable container-padding--zero lg:container-padding--auto">
              <ul className="navbar-menu text-white">
                <li className="navbar-menu__item">
                  <NavLink className="navbar-menu__link" to="/">
                    Přímý přenos
                  </NavLink>
                </li>
                <li className="navbar-menu__item">
                  <NavLink className="navbar-menu__link" to="/program">
                    Program
                  </NavLink>
                </li>
                <li className="navbar-menu__item">
                  <NavLink className="navbar-menu__link" to="/protocol">
                    Zápis
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="navbar__actions navbar__section navbar__section--expandable container-padding--zero lg:container-padding--auto self-start flex flex-row items-center justify-between">
              <div className="order-last lg:order-first lg:mr-8">
                {connectionIndicator}
              </div>
              {!isAuthenticated && (
                <Button className="btn--white joyride-login" onClick={login}>
                  Přihlásit se
                </Button>
              )}
              {isAuthenticated && (
                <div className="flex items-center space-x-4 joyride-login">
                  <span className="head-heavy-2xs">{user.name}</span>
                  <div className="avatar avatar--2xs">
                    <img
                      src={`https://a.pirati.cz/piratar/200/${user.username}.jpg`}
                      alt="Avatar"
                    />
                  </div>
                  <button
                    onClick={logout}
                    className="text-grey-200 hover:text-white"
                    aria-label="Odhlásit se"
                    data-tip="Odhlásit se"
                    data-tip-at="bottom"
                  >
                    <i className="ico--log-out"></i>
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
