import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useWindowSize from "@rooks/use-window-size";
import classNames from "classnames";

const Footer = () => {
  const { innerWidth } = useWindowSize();
  const [showCfMenu, setShowCfMenu] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const isLg = innerWidth >= 1024;

  return (
    <footer className="footer bg-grey-700 text-white">
      <div className="footer__main py-4 lg:py-16 container container--default">
        <section className="footer__brand">
          <img
            src={`${process.env.REACT_APP_STYLEGUIDE_URL}/images/logo-full-white.svg`}
            alt=""
            className="w-32 md:w-40 pb-6"
          />
          <p className="para hidden md:block md:mb-4 lg:mb-0 text-grey-200">
            Piráti, 2022. Všechna práva vyhlazena. Sdílejte a nechte ostatní
            sdílet za stejných podmínek.
          </p>
        </section>
        <section className="footer__main-links bg-grey-700 text-white lg:grid grid-cols-2 gap-4">
          <div className="pt-8 pb-4 lg:py-0">
            <div className="footer-collapsible">
              <span
                className={classNames(
                  "text-xl uppercase text-white footer-collapsible__toggle",
                  {
                    "footer-collapsible__toggle--open": showCfMenu,
                  }
                )}
                onClick={() => setShowCfMenu(!showCfMenu)}
              >
                CF 2022
              </span>{" "}
              <div className={showCfMenu || isLg ? "" : "hidden"}>
                <ul className="mt-6 space-y-2 text-grey-200">
                  <li>
                    <NavLink to="/">Přímý přenos</NavLink>
                  </li>
                  <li>
                    <NavLink to="/program">Program</NavLink>
                  </li>
                  <li>
                    <NavLink to="/protocol">Zápis</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">Co je to celostátní fórum?</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="py-4 lg:py-0 border-t border-grey-400 lg:border-t-0">
            <div className="footer-collapsible">
              <span
                className={classNames(
                  "text-xl uppercase text-white footer-collapsible__toggle",
                  {
                    "footer-collapsible__toggle--open": showOtherMenu,
                  }
                )}
                onClick={() => setShowOtherMenu(!showOtherMenu)}
              >
                Otevřenost
              </span>{" "}
              <div className={showOtherMenu || isLg ? "" : "hidden"}>
                <ul className="mt-6 space-y-2 text-grey-200">
                  <li>
                    <a href="https://ucet.pirati.cz">Transparentní účet</a>
                  </li>{" "}
                  <li>
                    <a href="https://smlouvy.pirati.cz">Registr smluv</a>
                  </li>{" "}
                  <li>
                    <a href="https://wiki.pirati.cz/fo/otevrene_ucetnictvi">
                      Otevřené účetnictví
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="footer__social lg:text-right">
          <div className="flex flex-col md:flex-row lg:flex-col lg:items-end space-y-2 md:space-y-0 md:space-x-2 lg:space-x-0 lg:space-y-2">
            <a
              href="https://dary.pirati.cz"
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn--icon btn--cyan-200 btn--hoveractive text-lg btn--fullwidth sm:btn--autowidth"
            >
              <div className="btn__body-wrap">
                <div className="btn__body ">Přispěj</div>{" "}
                <div className="btn__icon ">
                  <i className="ico--pig"></i>
                </div>
              </div>
            </a>{" "}
            <a
              href="https://nalodeni.pirati.cz"
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn--icon btn--blue-300 btn--hoveractive text-lg btn--fullwidth sm:btn--autowidth"
            >
              <div className="btn__body-wrap">
                <div className="btn__body ">Naloď se</div>{" "}
                <div className="btn__icon ">
                  <i className="ico--anchor"></i>
                </div>
              </div>
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
